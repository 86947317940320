<template>
  <div>
    <div class="login-box">
      <div class="login-wrap">
        <div class="account-login cent">
          <div>
            <a :class="['tab', { active: !isPhoneCode }]" @click="isPhoneCode = false">
              密码登录
            </a>
            <a :class="['tab', { active: isPhoneCode }]" @click="isPhoneCode = true">
              短信登录
            </a>
          </div>
          <a-form-model
            id="components-form-demo-normal-login"
            ref="loginForm"
            :hideRequiredMark="true"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            @submit="handleLogin"
          >
            <a-form-model-item class="mb-10" prop="username">
              <a-input
                v-model="loginForm.username"
                placeholder="请输入手机号码">
                <div slot="prefix" class="icon-box">
                  <a-icon theme="filled" type="phone" />
                </div>
              </a-input>
            </a-form-model-item>
            <a-form-model-item v-if="!isPhoneCode" :colon="false" class="mb-5" prop="password">
              <a-input v-model="loginForm.password" placeholder="请输入密码" type="password">
                <div slot="prefix" class="icon-box">
                  <a-icon theme="filled" type="lock" />
                </div>
              </a-input>
            </a-form-model-item>
            <a-form-item :colon="false" class="mb-10" prop="code">
              <a-input
                v-model="loginForm.code"
                class="mr-10"
                placeholder="验证码"
                style="width: calc(100% - 110px);"
              >
                <div slot="prefix" class="icon-box">
                  <a-icon theme="filled" type="rocket" />
                </div>
              </a-input>
              <img
                v-if="!isPhoneCode"
                :src="code.src"
                height="40"
                style="margin-top: -2px;cursor: pointer"
                width="100"
                @click="refreshCode">
              <a-button v-else :disabled="this.PhoneCode.status === 1" type="primary" @click="getPhoneCode">
                {{ codeText }}
              </a-button>
            </a-form-item>

            <div :colon="false" class="mb-10 dis">
              <div>

              </div>
              <div>
                <a class="font-bold text-dark" href="javascript:void(0)" @click="$router.push('/forgetPassword')">忘记密码</a> |
                <a class="font-bold text-dark" href="/registerTypeSelect" target="_blank">免费注册</a>
              </div>
            </div>
            <a-form-item>
              <a-button block class="login-form-button" html-type="submit" type="danger">
                登 录
              </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </div>
    </div>

    <el-dialog :show-close="false" :visible.sync="dialogVisible" center title="提示" width="30%">
      <div>
        您提交的资料未审核通过，请你重新编辑提交。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="$router.push({path:'/registerSteps?id=1'})">编 辑</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>

import { randomLenNum } from '@/utils/utils'
import { sendSmsCode, verifyUser } from '@/api/Login'
import { getUserInfoRz } from '@/api/Login'

export default {
  name: 'Login',
  data() {
    return {
      dialogVisible: false,
      PhoneCodeType: '1', // 1、登录；2、绑定；3、解绑
      isPhoneCode: false, // true 手机验证码登录
      loginRules: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的手机号码' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '验证码长度为4位', trigger: 'blur' }
        ]
      },
      loginForm: {
        username: '',
        password: '',
        code: '',
        redomStr: ''
      },
      code: {
        src: process.env.NODE_ENV === 'development' ? '/api/code' : '/code',
        value: '',
        len: 4,
        type: 'image'
      },
      PhoneCode: {
        status: 0, // 0 获取验证码 1 已发送{count}秒 2 重新发送
        count: '',
        timer: null
      },
      infoProfect: ''
    }
  },
  created() {
    this.onJumpLogin()
    this.refreshCode()
    this.timing()
  },
  computed: {
    codeText() {
      if (this.PhoneCode.status === 1) {
        return `验证码已发送 ${this.PhoneCode.count}秒`
      } else if (this.PhoneCode.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    },
  },
  methods: {
    onJumpLogin() {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.location.replace(PAGE_URL)
    },
    timing() {
      let times = 1000 * 30
      setInterval(() => {
        this.loginForm.code = ''
        this.loginForm.randomStr = randomLenNum(this.code.len, true)
        this.code.type === 'text'
          ? (this.code.value = randomLenNum(this.code.len))
          : (this.code.src = `${window.location.origin}${process.env.NODE_ENV === 'development' ? '/api' : ''}/code?randomStr=${this.loginForm.randomStr}`)
      }, times)
    },
    /**
     * 刷新验证码
     */
    refreshCode() {
      this.loginForm.code = ''
      this.loginForm.randomStr = randomLenNum(this.code.len, true)
      this.code.type === 'text'
        ? (this.code.value = randomLenNum(this.code.len))
        : (this.code.src = `${window.location.origin}${process.env.NODE_ENV === 'development' ? '/api' : ''}/code?randomStr=${this.loginForm.randomStr}`)
    },
    getPhoneCode() {

      this.$refs.loginForm.validateField('username', (errorMessage) => {
        if (errorMessage === '') {
          if (!this.timer) {
            sendSmsCode({
              phone: this.loginForm.username,
              type: this.PhoneCodeType
            }).then(() => {
              this.$notification.success({
                message: '发送验证码成功',
                description: '您已成功发送验证，请注意查收'
              })
              this.PhoneCode.count = times
              this.PhoneCode.status = 1
              this.PhoneCode.timer = setInterval(() => {
                if (this.PhoneCode.count > 0 && this.PhoneCode.count <= times) {
                  this.PhoneCode.count--
                } else {
                  this.PhoneCode.status = 2
                  clearInterval(this.PhoneCode.timer)
                  this.PhoneCode.timer = null
                }
              }, 1000)
            })
          }
        }
      })
      const times = 60 // 倒计时时间
    },
    // 点击登录
    handleLogin(e) {
      e.preventDefault()
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const data = this.isPhoneCode ? { phone: this.loginForm.username, code: this.loginForm.code } : this.loginForm
          this.$store.dispatch(this.isPhoneCode ? 'LoginByPhone' : 'LoginByUsername', data).then(() => {
            if (this.$store.getters.infoProfect == 1) {
              getUserInfoRz().then((res) => {
                if (res.data.confJson == null || res.data.confJson == '' || res.data.confJson == undefined) {
                  this.$store.commit('SET_IS_ENABLE_CH', 0)
                  this.$store.commit('SET_IS_OPEN_DOWN', 0)
                  this.$store.commit('SET_IS_HANDPICK_GOODS', 0)
                  this.$store.commit('SET_IS_JDGOODS', 0)
                  this.$store.commit('SET_MENG_DA', 0)
                } else {
                  let configeInfo = JSON.parse(res.data.confJson)
                  this.$store.commit('SET_IS_ENABLE_CH', Number(configeInfo.isEnableCh))
                  this.$store.commit('SET_IS_OPEN_DOWN', Number(configeInfo.isOpenDown))
                  this.$store.commit('SET_IS_HANDPICK_GOODS', Number(configeInfo.isHandpickGoods))
                  this.$store.commit('SET_IS_JDGOODS', Number(configeInfo.isJDGoods))
                  this.$store.commit('SET_MENG_DA', Number(configeInfo.isMengDa))
                }
              })
            }
            this.$store.dispatch('GetUserInfo').then((res) => {
              if (res.sysUser.infoProfect === 0) {
                this.$router.push({ path: '/registerSteps?id=1' })
              } else if (res.sysUser.infoProfect === 3) {
                this.dialogVisible = true
              } else {
                this.$router.push({ path: '/' })
              }
            })
            // this.$router.go(0)
          }).catch((err) => {
            console.log(err)
            this.refreshCode()
          })
        }
      })
    }

  }
}

</script>

<style lang="scss">
.dis {
  display: flex;
  justify-content: space-between;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 45px;
}

//.ant-input:hover {
//  border-color: #e4393c;
//}
.login-box {
  background: url('https://file.jingpingo.com/supply/material/a6375fe4-987c-4c23-a0f2-8145642a01a0.png') no-repeat;
  background-position: center;
  background-position-y: inherit;
  height: 600px;
}

.login-box .login-wrap {
  margin: 0 auto;
  padding-top: 122px;
  width: 1200px;
  height: 660px;
  display: flex;
  justify-content: flex-end;
}

.login-box .login-wrap .account-login {
  height: 360px;
  width: 360px;
  background-color: #fff;
  padding: 15px 20px 0;
  border: 5px solid #eee;
  position: relative;

  .cent {
    text-align: center;
  }

  a {
    font: 400 12px SimSun;
    color: #6c6c6c;
    text-decoration: none;

    &:hover {
      color: #e4393c;
    }
  }

  .tab {
    display: inline-block;
    height: 18px;
    line-height: 5px;
    font-size: 16px;
    color: #3c3c3c;
    margin: 9px 10px 12px 0;
    font-weight: 700;
    font-family: "Microsoft YaHei", sans-serif;

    &.active {
      border-bottom: 2px solid #000;
    }
  }
}

.login-form {
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form-button {
  background-color: #ff4000;
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 400;
}

.icon-box {
  position: absolute;
  top: -19px;
  height: 38px;
  left: -11px;
  width: 38px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;

}
</style>
